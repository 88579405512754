import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';
import Moment from 'moment';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};

export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('dueidee_current_user') != null
        ? JSON.parse(localStorage.getItem('dueidee_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      user.img = '/assets/img/profiles/default-user.jpg?t='+Date.now();
      localStorage.setItem('dueidee_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('dueidee_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getOauthToken = () => {
  let oauthToken = null;
  try {
    oauthToken =
      localStorage.getItem('dueidee_current_oauth_token') != null
        ? JSON.parse(localStorage.getItem('dueidee_current_oauth_token'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : setOauthToken -> error', error);
    user = null;
  }
  return oauthToken;
};

export const setOauthToken = (oauthToken) => {
  try {
    if (oauthToken) {
      localStorage.setItem('dueidee_current_oauth_token', JSON.stringify(oauthToken));
    } else {
      localStorage.removeItem('dueidee_current_oauth_token');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setOauthToken -> error', error);
  }
};

export const getStoreProductPrice = (product, order) => {
  try {
    if (!product || !product.price) {
      throw new Error('Producto o precios no definidos');
    }

    const currentDate = order && order.date ? Moment(order.date).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD");

    const parseDate = (dateString) => {
      if (!dateString) return null;
      const parts = dateString.split('-');
      if (parts.length !== 3) return null;
      return Moment(`${parts[2]}-${parts[1]}-${parts[0]}`).format("YYYY-MM-DD");
    };

    const specialFromDate = parseDate(product.specialFromDate) || currentDate;
    const specialToDate = parseDate(product.specialToDate) || currentDate;

    let price;
    if (currentDate >= specialFromDate && currentDate < specialToDate) {
      const specialPrice = product.price.find(price => price.type === 'special');
      price = specialPrice && specialPrice.value != null ? specialPrice.value : product.price.find(price => price.type === 'internet').value;
    } else {
      const internetPrice = product.price.find(price => price.type === 'internet');
      if (!internetPrice || internetPrice.value == null) {
        throw new Error('Precio de internet no definido');
      }
      price = internetPrice.value;
    }

    return price != null ? price : 0; // Asegurarse de devolver un valor adecuado
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getStoreProductPrice -> error', error);
    return 0; // Devolvemos 0 en caso de error
  }
};




export const getShortStoreLocation = (location) => {
  return location.split('#')[1].replace('tienda.lovleis.','');
};

export const getMediumStoreLocation = (location) => {
  return location.split('#')[1].replace('tienda.lovleis.','')+' '+location.split('#')[2].replaceAll('.',' ');
};

export const getFullStoreLocation = (location) => {
  return location.split('#')[1].replaceAll('.',' ')+' '+location.split('#')[2].replaceAll('.',' ');
};

export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertToValidFilename = (fileName) => {
  var newFileName = '';
  newFileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  newFileName = newFileName.replace(/\s/g, '-');
  newFileName = newFileName.replace('|', '-');
  newFileName = newFileName.replace('..', '.');
  newFileName = newFileName.toLowerCase();

  return newFileName;
};


export function base64ToBlob(base64, type = "application/pdf") {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type });
}



export const getCustomerUser = () => {
  let customer = null;
  try {
    
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCustomerUser -> error', error);
    customer = null;
  }
  return customer;
};