import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientID, backendApiUrl, backendOauthUrl, adminRoot, UserRole } from 'constants/defaultValues';
import { setCurrentUser, setOauthToken } from 'helpers/Utils';
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

/*
EJEMPLO:
https://start.jcolemorrison.com/react-and-redux-sagas-authentication-app-tutorial/
*/

async function signInApi (email, password) {
  const signInUrl = backendOauthUrl+'/v1/login';
  return await fetch(signInUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, 'client_id' : clientID }),
  })
    .then(response => response.json())
    .then(json => json)
    .catch((error) => { throw error })
}

async function sendPasswordResetEmail (email) {
  const accountForgotPasswordUrl = backendApiUrl+'/v1/accounts/'+ Buffer.from(email).toString('base64')+'/password';
  return await fetch(accountForgotPasswordUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'due-idee-client-id': 'front.sms.dueidee',
    },
  })
    .then(response => response)
    .then(json => json)
    .catch((error) => { throw error })
}

async function sendResetPassword (email, resetPasswordCode, newPassword) {
  const accountResetPasswordUrl = backendApiUrl+'/v1/accounts/'+ Buffer.from(email).toString('base64')+'/password';
  return await fetch(accountResetPasswordUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '.concat(resetPasswordCode),
    },
    body: JSON.stringify({'password': newPassword}),
  })
    .then(response => response)
    .then(json => json)
    .catch((error) => { throw error })
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginResult = yield signInApi(email, password);

    if (!loginResult.status) {

      let currentUser = {
        id : loginResult.user.email,
        date : loginResult.user.createDate,
        ... loginResult.user
       };

      //User disabled security validation
      if (loginResult.user.disabled){
        return history.push('/unauthorized');
      }       

      setCurrentUser(currentUser);
      setOauthToken(loginResult.security);
      yield put(loginUserSuccess(currentUser));

      //Reset Password for security validation
      if (loginResult.user.email.split("@")[0] == password){
        return history.push('/user/reset-password/'+Buffer.from(loginResult.security.access_token+';'+email).toString('base64'));
      }

      switch (currentUser.role) {
        case UserRole.admin:
          history.push(adminRoot+'/dashboards');
          break;
        case UserRole.storeManager:
        case UserRole.seller:
            history.push(adminRoot+'/seller');
            break;          
        case UserRole.sellerMarketplace:
          history.push(adminRoot+'/marketplace');
          break;          
      
        default:
          history.push(adminRoot);
          break;
      }
    } else {
      yield put(loginUserError(loginResult.detail));
    }    
  } catch (error) {
    yield put(loginUserError(error));
  }
}


export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  setOauthToken();
  history.push(adminRoot+'/user/login');
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordResult = yield sendPasswordResetEmail(email);
    if (forgotPasswordResult.status == 204) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordResult.statusText));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPassword({ payload }) {
  const { email, newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordResult = yield sendResetPassword(
      email,
      resetPasswordCode,
      newPassword
    );
    if (resetPasswordResult.status == 204) {
      yield put(resetPasswordSuccess('success'));
      
    } else if (resetPasswordResult.status == 401) {
      yield put(resetPasswordError('Unauthorized'));

    } else {
      yield put(resetPasswordError(resetPasswordResult.statusText));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
